import React from 'react'
import { InputContainer } from './styles'
import styled from '@emotion/styled'

export type Props = React.HTMLProps<HTMLInputElement> & {
  containerProps?: React.HTMLProps<HTMLDivElement>
  hasError?: boolean

  value: boolean
  onChange: (val: boolean) => void
  label: string
}

const CheckedIcon = props => (
  <svg {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H14C15.933 0.5 17.5 2.067 17.5 4V14C17.5 15.933 15.933 17.5 14 17.5H4C2.067 17.5 0.5 15.933 0.5 14V4Z"
      fill="#F9F8F7" stroke="#00A095" />
    <path d="M4.83301 8.31826L7.77418 11.5001L13.1663 5.66675" stroke="#00A095" strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round" />
  </svg>
)

const UncheckedIcon = props => (
  <svg {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H14C15.933 0.5 17.5 2.067 17.5 4V14C17.5 15.933 15.933 17.5 14 17.5H4C2.067 17.5 0.5 15.933 0.5 14V4Z"
      stroke="#C7C7C8" />
  </svg>
)

const Label = styled.div`
  margin-left: 13px;
  font-size: 12px;
  line-height: 1.6;
  color: #1D1E21;
  
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.green100};
    font-weight: bold;
  }
`

const HiddenCheckbox = styled.input`
  position: absolute;
  width: 18px;
  height: 18px;
  opacity: 0;
  cursor: pointer;
`

const CheckboxContainer = styled.div`
  font-size: 1em;
`

const Checkbox: React.FC<Props> = props => {
  const {
    containerProps,
    value,
    onChange,
    label,
  } = props

  return (
    <InputContainer {...containerProps}>
      <div style={{ display: 'flex' }}>
        <HiddenCheckbox
          checked={value}
          type="checkbox"
          onChange={() => onChange(!value)}
        />
        <CheckboxContainer>
          {value ? <CheckedIcon width={16} /> : <UncheckedIcon width={16} />}
        </CheckboxContainer>
      </div>
      <Label>{label}</Label>
    </InputContainer>
  )
}

export default Checkbox
