import * as React from 'react'
import {
  ActionsContainer,
  Container,
  Error,
  Errors,
  FieldContainer,
  InnerContainer,
  Label,
  LabelContainer,
} from './styles'

export enum FieldDirection {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

type Props = {
  position: FieldDirection
  label?: string
  labelWidth?: number
  errors?: string[]
  showTextErrors?: boolean
  htmlFor?: string
  actions?: React.ReactNode
  hint?: string
  inlineHint?: string
} & React.HTMLProps<HTMLDivElement>;

const Field: React.FC<Props> = props => {
  const {
    position,
    label,
    labelWidth,
    errors,
    showTextErrors,
    children,
    htmlFor,
    actions,
    hint = '',
    inlineHint = '',
    ...rest
  } = props
  return (
    <Container {...rest}>
      <InnerContainer position={position}>
        <LabelContainer>
          <Label
            position={position}
            width={labelWidth}
            hasError={errors?.length > 0}
            htmlFor={htmlFor}
          >
            {label}
          </Label>
          {actions && <ActionsContainer>{actions}</ActionsContainer>}
        </LabelContainer>
        <FieldContainer>{children}</FieldContainer>
      </InnerContainer>
      {errors && errors.length > 0 && showTextErrors
      && (
        <Errors>
          {errors.map(i => (<Error key={i}>{i}</Error>))}
        </Errors>
      )}
    </Container>
  )
}

export default Field
