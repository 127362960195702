import React from 'react'

const LinkedInIcon = props => (
  <svg {...props} width="15" height="15" viewBox="0 0 15 15" fill="#616164" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.485326 5.06471H3.49664V14.75H0.485326V5.06471ZM1.99432 0.25C2.33932 0.25 2.67657 0.352467 2.96343 0.544446C3.25028 0.736426 3.47385 1.0093 3.60588 1.32855C3.7379 1.6478 3.77243 1.99909 3.70513 2.33801C3.63782 2.67692 3.4717 2.98824 3.22775 3.23258C2.9838 3.47692 2.67299 3.64331 2.33463 3.71073C1.99626 3.77814 1.64553 3.74354 1.3268 3.6113C1.00806 3.47906 0.73564 3.25513 0.543971 2.96781C0.352302 2.68049 0.25 2.3427 0.25 1.99714C0.250591 1.53376 0.434709 1.08954 0.761947 0.761983C1.08919 0.434425 1.53169 0.250296 1.99432 0.25Z" />
    <path d="M5.3826 5.06305H8.26118V6.39241H8.30022C8.58872 5.8982 9.00574 5.49169 9.5068 5.21626C10.0079 4.94083 10.5742 4.80678 11.1453 4.82844C14.1856 4.82844 14.75 6.83253 14.75 9.44208V14.7494H11.7487V10.0409C11.7487 8.91593 11.732 7.4715 10.1873 7.4715C8.62589 7.4715 8.38722 8.70032 8.38722 9.95706V14.7494H5.38594L5.38037 5.06414L5.3826 5.06305Z" />
  </svg>
)

export default LinkedInIcon
