import * as React from 'react'
import { uniqueId } from 'lodash-es'
import Field, { FieldDirection } from './field/field'
import { Validation } from './validations'
import { FormContext } from './smart-form'
import Checkbox from './checkbox/checkbox'

type Props = {
  name: string

  label: string | React.ReactNode
  defaultValue?: boolean

  direction?: FieldDirection

  validations?: Validation[]
  validationGroup?: string
  autoValidate?: boolean
  validateOnChangeWithError?: boolean
  hideErrors?: boolean

  className?: string
};

const SmartCheckboxField: React.FC<Props> = props => {
  const {
    name,
    label,
    direction = FieldDirection.VERTICAL,
    validations = [],
    validationGroup,
    className,
    hideErrors = false,
    validateOnChangeWithError = true,
    defaultValue = false,
  } = props

  const { form, fields } = React.useContext(FormContext)
  const id = React.useMemo(() => uniqueId(`${form.id}-${name}-`), [])

  React.useEffect(() => {
    form.registerField(name, label, validations, validationGroup, defaultValue)
  }, [])

  React.useEffect(() => () => {
    form.unregisterField(name)
  }, [])

  const errors = fields[name]?.errors || []
  return (
    <Field
      position={direction}
      label=""
      errors={errors}
      className={className}
      showTextErrors={!hideErrors}
      htmlFor={id}
    >
      <Checkbox
        label={label}
        value={fields[name]?.value || false}
        onChange={value => {
          form.setFieldValue(name, value)
          if (validateOnChangeWithError && errors.length > 0) form.validateField(name)
        }}
        hasError={errors.length > 0}
      />
    </Field>
  )
}
export default SmartCheckboxField
