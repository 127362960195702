import React from 'react'
import { isNil } from 'lodash-es'
import { Rifm } from 'rifm'
import { InputPreset } from './types'
import { AutoCompleteContainer, AutoCompleteItem, IconContainer, InputContainer, StyledInput } from './styles'

type SuggestionItem<T extends {}> = T & {
  label: string
}

// @ts-ignore
export interface Props<T> extends React.HTMLProps<HTMLInputElement> {
  containerProps?: React.HTMLProps<HTMLDivElement>
  icon?: React.ReactNode
  onIconClick?: () => void
  preset?: InputPreset
  hasError?: boolean
  suggestions?: SuggestionItem<T>[]
  onChooseSuggestion?: (item: T) => void

  value: string
  onChange: (str: string) => void
  format?: (str: string) => string
  replace?: (str: string) => string
  append?: (str: string) => string
  mask?: boolean
  acceptValue?: RegExp

  inputRef?: any
}

type State = {
  selectedSuggestion: number
}

// eslint-disable-next-line react/prefer-stateless-function
class Input<T> extends React.Component<Props<T>, State> {
  state = {
    selectedSuggestion: null,
  } as State

  componentDidUpdate(prevProps: Readonly<Props<T>>) {
    if (this.props.suggestions !== prevProps.suggestions) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedSuggestion: null })
    }
  }

  render() {
    const {
      icon,
      containerProps,
      onIconClick,
      preset,
      suggestions = [],
      onChooseSuggestion,
      value,
      onChange,
      format = s => s,
      replace,
      append,
      mask,
      acceptValue = /.*/g,
      inputRef,
      ...rest
    } = this.props
    const { selectedSuggestion } = this.state
    const hasIcon = !isNil(this.props.icon)
    return (
      <InputContainer {...containerProps} preset={preset}>
        <Rifm
          value={value || ''}
          onChange={onChange}
          format={format}
          replace={replace}
          append={append}
          mask={mask}
          accept={acceptValue}
        >
          {({ onChange: maskedOnChange, value: maskedValue }) => (
            <StyledInput
              {...rest}
              ref={inputRef}
              preset={preset}
              hasIcon={hasIcon}
              value={maskedValue}
              onChange={maskedOnChange}
              onKeyUp={e => {
                if (!suggestions.length) {
                  return
                }
                if (e.keyCode === 40) { // DOWN
                  if (this.state.selectedSuggestion === null
                    || this.state.selectedSuggestion === suggestions.length - 1) {
                    this.setState({ selectedSuggestion: 0 })
                  } else {
                    this.setState(old => ({ selectedSuggestion: old.selectedSuggestion + 1 }))
                  }
                }
                if (e.keyCode === 38) { // UP
                  if (this.state.selectedSuggestion === null
                    || this.state.selectedSuggestion === 0) {
                    this.setState({ selectedSuggestion: suggestions.length - 1 })
                  } else {
                    this.setState(old => ({ selectedSuggestion: old.selectedSuggestion - 1 }))
                  }
                }
                if (e.keyCode === 13) { // ENTER
                  if (this.state.selectedSuggestion !== null) {
                    e.preventDefault()
                    e.stopPropagation()
                    onChooseSuggestion(suggestions[this.state.selectedSuggestion])
                  }
                }
              }}
            />
          )}
        </Rifm>
        {hasIcon && (
          <IconContainer
            onClick={onIconClick}
          >
            {icon}
          </IconContainer>
        )}
        {suggestions.length > 0 && (
          <AutoCompleteContainer>
            {suggestions.map((i, k) => (
              <AutoCompleteItem
                key={`suggestion_${k}`}
                onMouseEnter={() => this.setState({ selectedSuggestion: k })}
                onMouseLeave={() => this.setState({ selectedSuggestion: null })}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  onChooseSuggestion(i)
                }}
                isActive={k === selectedSuggestion}
              >
                {i.label}
              </AutoCompleteItem>
            ))}
          </AutoCompleteContainer>
        )}
      </InputContainer>
    )
  }
}

export default Input
export { InputPreset }
