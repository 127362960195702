import React from 'react'

const TwitterIcon = props => (
  <svg {...props} width="18" height="15" viewBox="0 0 18 15" fill="#616164" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.8877 2.02829C17.2095 2.31424 16.4926 2.49874 15.7602 2.57581C16.5195 2.10595 17.0806 1.37612 17.3382 0.523295C16.6213 0.959388 15.8301 1.26076 15.0041 1.41244C14.2998 0.690036 13.3386 0.272526 12.3281 0.25C11.3654 0.256619 10.4439 0.640652 9.76314 1.31902C9.08236 1.9974 8.69696 2.91556 8.69031 3.8749C8.67899 4.15028 8.70202 4.426 8.75888 4.69572C7.31849 4.6508 5.90469 4.29736 4.6136 3.65944C3.32251 3.02151 2.1844 2.11405 1.27665 0.998753C0.971616 1.56905 0.805887 2.203 0.792921 2.84911C0.777725 3.45116 0.922435 4.04653 1.2124 4.57493C1.50237 5.10334 1.92729 5.54602 2.44416 5.85816C1.84216 5.83673 1.25392 5.67278 0.728111 5.3799V5.44822C0.723567 6.29174 1.01661 7.11003 1.55605 7.76012C2.09548 8.41021 2.847 8.85076 3.6793 9.0048C3.36566 9.08856 3.04306 9.13443 2.71843 9.14145C2.48743 9.15268 2.25603 9.12965 2.03182 9.07312C2.26235 9.80178 2.71872 10.4389 3.33537 10.8929C3.95201 11.347 4.69721 11.5946 5.46392 11.6002C4.17172 12.6129 2.57777 13.1678 0.933812 13.1772C0.634696 13.1882 0.335304 13.165 0.0415039 13.108C1.72508 14.1721 3.67591 14.7412 5.66962 14.7496C7.03722 14.7615 8.39361 14.503 9.6603 13.9891C10.927 13.4752 12.0788 12.716 13.0491 11.7556C14.0194 10.7952 14.7889 9.65252 15.3131 8.39379C15.8373 7.13506 16.1058 5.78523 16.103 4.42243V3.94323C16.8004 3.40799 17.4035 2.76084 17.8877 2.02829Z"/>
  </svg>

)

export default TwitterIcon
