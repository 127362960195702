export enum ValidationType {
  REQUIRED,
  EMAIL,
  HOSTNAME,
  CUSTOM,
  CHECKBOX
}

type BaseValidation = { customError?: string }
type RequiredValidation = BaseValidation & { type: ValidationType.REQUIRED }
type EmailValidation = BaseValidation & { type: ValidationType.EMAIL }
type HostnameValidation = BaseValidation & { type: ValidationType.HOSTNAME }
type CheckboxValidation = BaseValidation & { type: ValidationType.CHECKBOX }
type CustomValidation =
  BaseValidation
  & { type: ValidationType.CUSTOM; rule(value: Value): boolean; customError: string }

export type Validation =
  | RequiredValidation
  | EmailValidation
  | HostnameValidation
  | CustomValidation
  | CheckboxValidation;

type Value = string | number | null | boolean
const validators = {
  [ValidationType.REQUIRED]: (value: Value, label: string, config: RequiredValidation): string => {
    if (typeof value !== 'undefined' && value !== null && value !== '') {
      return null
    }
    return config.customError || `Please provide ${label.toLowerCase()}`
  },

  [ValidationType.EMAIL]: (value: Value, label: string, config: EmailValidation): string => {
    if (`${value}`.length === 0) return null
    if (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(`${value}`)) {
      return null
    }
    return config.customError?.replace('{label}', label) || 'Please enter valid email.'
  },

  [ValidationType.HOSTNAME]: (value: Value, label: string, config: HostnameValidation): string => {
    if (`${value}`.length === 0) return null
    if (/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])/.test(`${value}`)) {
      return null
    }
    return config.customError?.replace('{label}', label) || 'Please enter valid url.'
  },

  [ValidationType.CUSTOM]: (value: Value, label: string, config: CustomValidation): string => {
    if (`${value}`.length === 0) return null
    if (config.rule(value)) {
      return null
    }
    return config.customError.replace('{label}', label)
  },

  [ValidationType.CHECKBOX]: (value: Value, label: string, config: CustomValidation): string => {
    if(value !== true) {
      return config.customError?.replace('{label}', label) || 'This checkbox is required.'
    }
    return null
  },
}

export default validators
