import * as React from 'react'
import { uniqueId } from 'lodash-es'
import Field, { FieldDirection } from './field/field'
import { InputPreset } from './input/input'
import { Validation } from './validations'
import NumberInput from './number-input/number-input'
import { FormContext } from './smart-form'

type Props = {
  name: string

  label: string
  labelActions?: React.ReactNode

  hint?: string
  inlineHint?: string

  placeholder?: string
  type?: 'text' | 'password' | 'tel'
  format?: string
  decimalScale?: number
  thousandSeparator?: boolean
  mask?: string
  allowNegative?: boolean
  direction?: FieldDirection
  preset?: InputPreset
  autoComplete?: string

  validations?: Validation[]
  validationGroup?: string
  autoValidate?: boolean
  validateOnChangeWithError?: boolean
  hideErrors?: boolean

  icon?: React.ReactNode
  onIconClick?: () => void

  className?: string
};

const SmartNumberInputField: React.FC<Props> = props => {
  const {
    name,
    label,
    type = 'text',
    placeholder,
    autoComplete,
    direction = FieldDirection.VERTICAL,
    preset = InputPreset.NORMAL,
    validations = [],
    validationGroup,
    autoValidate = false,
    icon,
    onIconClick,
    className,
    hideErrors = false,
    validateOnChangeWithError = true,
    labelActions,
    format,
    decimalScale,
    thousandSeparator = false,
    mask,
    allowNegative = false,
    hint,
    inlineHint,
  } = props

  const { form, fields } = React.useContext(FormContext)
  const id = React.useMemo(() => uniqueId(`${form.id}-${name}-`), [])

  React.useEffect(() => {
    form.registerField(name, label, validations, validationGroup)
  }, [])

  React.useEffect(() => () => {
    form.unregisterField(name)
  }, [])

  const errors = fields[name]?.errors || []
  return (
    <Field
      position={direction}
      label={label}
      errors={errors}
      className={className}
      showTextErrors={!hideErrors}
      htmlFor={id}
      actions={labelActions}
      hint={hint}
      inlineHint={inlineHint}
    >
      <NumberInput
        name={name}
        placeholder={placeholder}
        type={type}
        preset={preset}
        autoComplete={autoComplete}
        value={fields[name]?.value || ''}
        onValueChange={val => {
          form.setFieldValue(name, val.value)
          if (validateOnChangeWithError && errors.length > 0) form.validateField(name)
        }}
        hasError={errors.length > 0}
        onBlur={() => autoValidate && form.validateField(name)}
        icon={icon}
        onIconClick={onIconClick}
        id={id}
        format={format}
        decimalScale={decimalScale}
        thousandSeparator={thousandSeparator ? ',' : null}
        mask={mask}
        allowNegative={allowNegative}
      />
    </Field>
  )
}
export default SmartNumberInputField
