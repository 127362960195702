import { css } from '@emotion/core'

export type ComCardThemeSettings = {
  named: {
    backgroundColor: string
  }
  colors: {
    blackRGB: string
    black100: string
    black64: string
    black12: string
    blueRGB: string
    blue100: string
    blue64: string
    blue12: string
    greenRGB: string
    green100: string
    green64: string
    green12: string
    white100: string
    grayRGB: string
    gray100: string
    gray64: string
    gray12: string
    orangeRGB: string
    orange100: string
    orange64: string
    orange12: string
    redRGB: string
    red100: string
    red64: string
    red12: string
  }
  fontWeight: {
    light: number
    normal: number
    semiBold: number
    bold: number
  }
  transitions: {
    textColor: string
  }
  presets: {
    link: string
  }
  zIndex: {
    tooltip: number
    select: number
    modal: number
  }
}

export const defaultTheme = {
  named: {
    backgroundColor: '#F5F7FB',
  },
  colors: {
    blackRGB: '94, 108, 132',
    black100: '#102A43',
    black64: '#667787',
    black12: '#E2E5E8',
    blueRGB: '62, 71, 125',
    blue100: '#3E477D',
    blue64: '#8489AC',
    blue12: '#E7E8EF',
    greenRGB: '37, 173, 129',
    green100: '#00A095',
    green64: '#6BCBC4',
    green12: '#D5F4F2',
    white100: '#FFFFFF',
    grayRGB: '94, 108, 132',
    gray100: '#5E6C84',
    gray64: '#98A1B0',
    gray12: '#EBEDF0',
    orangeRGB: '242, 153, 74',
    orange100: '#F2994A',
    orange64: '#F7BE8B',
    orange12: '#FDF2E9',
    redRGB: '242,10,52',
    red100: '#F20A34',
    red64: '#F7627D',
    red12: '#FDE1E6',
  },
  fontWeight: {
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
  },
  transitions: {
    textColor: 'color ease-in-out 100ms',
  },
  presets: {
    link: css`
  color: #00A095;
  text-decoration: none;
  transition: color ease-in-out 100ms;

  &:hover {
    color: #6BCBC4;
  }
`.styles,
  },
  zIndex: {
    tooltip: 11002,
    select: 11001,
    modal: 10001,
  },
} as ComCardThemeSettings
