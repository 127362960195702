import * as React from 'react'
import update from 'immutability-helper'
import { forEach, isFunction, mapValues, omit, set, uniqueId } from 'lodash-es'
import validators, { Validation } from './validations'

type ChildrenWithContext = (context: FormContext) => React.ReactNode

// @ts-ignore
interface Props extends React.HTMLProps<HTMLFormElement> {
  onSubmit?: (event: React.FormEvent, isValid: boolean, fields: { [name: string]: Field }, form: SmartForm) => void
  children?: React.ReactNode | ChildrenWithContext
}

type Field = {
  name: string
  label: string
  validations: Validation[]
  validationGroup?: string
  value: FieldValue
  defaultValue: FieldValue
  errors: string[]
}

type State = {
  fields: {
    [name: string]: Field
  }
};

export type FieldValue = string | number | null


type FormContext = {
  form: SmartForm
  fields: {
    [name: string]: Field
  }
}

export const FormContext = React.createContext<FormContext>(null)

class SmartForm extends React.Component<Props, State> {
  id = ''

  state = {
    fields: {},
  } as State

  constructor(props) {
    super(props)
    this.id = uniqueId('smart-form-')
  }

  // eslint-disable-next-line react/sort-comp
  registerField(name: string, label: string, validations: Validation[], group?: string, defaultValue?: FieldValue) {
    if (this.state.fields[name]) {
      this.setState(s => set(s, `fields.${name}.validation`, validations))
      return
    }
    this.setState(s => update(s, {
      fields: {
        $merge: {
          [name]: {
            name,
            label,
            validations,
            validationGroup: group,
            value: defaultValue || '',
            valueDefault: defaultValue || '',
            errors: [],
          },
        },
      },
    }))
  }

  unregisterField(name: string) {
    if (!this.state.fields[name]) {
      console.warn(`Field \`${name}\` not registered`)
      return
    }
    this.setState(s => update(s, {
      fields: {
        $unset: [name],
      },
    }))
  }

  setFieldValue(name: string, value: FieldValue, callback?: () => void) {
    this.setState(s => set(s, `fields.${name}.value`, value), callback)
  }

  validateField(name: string, updateStore = true): string[] {
    const errors: string[] = []
    const validations = this.state.fields[name]?.validations || []

    validations.forEach(v => {
      const error = validators[v.type](
        this.state.fields[name].value,
        this.state.fields[name].label,
        // @ts-ignore
        v,
      )
      if (error) {
        errors.push(error)
      }
    })

    if (updateStore) {
      this.setState(s => set(s, `fields.${name}.errors`, errors))
    }

    return errors
  }

  validateForm(updateStore = true, group?: string): boolean {
    const { fields } = this.state
    let hasErrors = false

    forEach(fields, field => {
      if (group && field.validationGroup !== group) {
        return
      }
      if (this.validateField(field.name, updateStore).length > 0) {
        hasErrors = true
      }
    })

    return !hasErrors
  }

  // eslint-disable-next-line react/sort-comp
  onSubmit(e: React.FormEvent) {
    const isValid = this.validateForm()
    if (isFunction(this.props.onSubmit)) {
      this.props.onSubmit(e, isValid, this.state.fields, this)
      return
    }
    if (!isValid) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  resetForm() {
    this.setState(prev => ({
      fields: mapValues(prev.fields, i => ({
        ...i,
        value: i.defaultValue,
        errors: [],
      })),
    }))
  }

  render() {
    return (
      <form onSubmit={this.onSubmit.bind(this)} {...omit(this.props, ['children', 'onSubmit'])}>
        <FormContext.Provider value={{ form: this, fields: this.state.fields }}>
          {
            isFunction(this.props.children)
              ? this.props.children({
                form: this,
                fields: this.state.fields,
              })
              : this.props.children
          }
        </FormContext.Provider>
      </form>
    )
  }
}

export default SmartForm
