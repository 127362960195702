import React from 'react'
import {
  SocialContainerHorizontal,
  SocialContainerVertical,
  StyledLink,
} from './styles'

import FacebookIcon from '../icons/facebook'
import TwitterIcon from '../icons/twitter'
import LinkedInIcon from '../icons/linkedin'
import InstagramIcon from '../icons/instagram'



const Link: React.FC<{href: string, lighter?: boolean, Icon: any }> = ({ href, lighter, Icon }) => (
  <StyledLink lighter={lighter} href={href} target="_blank">
    <Icon />
  </StyledLink>
)


const Social: React.FC<{ vertical?: boolean, lighter?: boolean }> = ({ vertical, lighter }) => {
  const Container = vertical
    ? SocialContainerVertical
    : SocialContainerHorizontal

  return (
    <Container>
      <Link href="https://www.facebook.com/comcard" Icon={FacebookIcon} lighter={lighter} />
      <Link href="https://twitter.com/comcard_us" Icon={TwitterIcon} lighter={lighter} />
      {/*<Link href="/" Icon={TelegramIcon} lighter={lighter} />*/}
      <Link href="https://www.linkedin.com/company/comcard" Icon={LinkedInIcon} lighter={lighter} />
      {/*<Link href="/" Icon={YoutubeIcon} lighter={lighter} />*/}
      <Link href="https://www.instagram.com/comcard_us" Icon={InstagramIcon} lighter={lighter} />
    </Container>
  )
}

export { Link, Social }
