import * as React from 'react'
import { useState } from 'react'
import Modal from '../common/modal'

type Props = {};

interface AlertsContextInterface {
  showAlert: (el: React.ReactNode) => void
}

export const AlertsContext = React.createContext<AlertsContextInterface | null>(null)

const Alerts: React.FC<Props> = props => {
  const [alert, setAlert] = React.useState<React.ReactNode>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const ctx = {
    showAlert: (el) => {
      setAlert(el)
      setIsModalOpen(true)
    },
  }

  return (
    <>
      <AlertsContext.Provider value={ctx}>
        {props.children}
      </AlertsContext.Provider>
      {alert !== null && (
        <Modal isOpen={isModalOpen} onClose={() => {
          setIsModalOpen(false)
          setAlert(null)
        }}>
          {alert}
        </Modal>
      )}
    </>
  )
}

export default Alerts
