import React from 'react'
import { NumberFormatProps } from 'react-number-format'
import { isNil, omit } from 'lodash-es'
import { InputPreset } from '../input/input'
import { IconContainer, InputContainer, NumberInputStyled } from './styles'

type Props = {
  containerProps?: React.HTMLProps<HTMLDivElement>
  icon?: React.ReactNode
  onIconClick?: () => void
  preset?: InputPreset
  hasError?: boolean
} & NumberFormatProps


const NumberInput: React.FC<Props> = props => {
  const hasIcon = !isNil(props.icon)
  return (
    <InputContainer
      {...props.containerProps}
      preset={props.preset}
      hasError={props.hasError}
      hasIcon={hasIcon}
    >
      <NumberInputStyled {...omit(props, ['containerProps', 'icon', 'onIconClick'])} />
      {hasIcon && (
        <IconContainer
          onClick={props.onIconClick}
        >
          {props.icon}
        </IconContainer>
      )}
    </InputContainer>
  )
}

export const CurrencyInput: React.FC<Props> = props => (
  <NumberInput
    decimalScale={2}
    fixedDecimalScale
    prefix="$"
    thousandSeparator
    {...props}
  />
)

export const PercentInput: React.FC<Props> = props => (
  <NumberInput
    decimalScale={2}
    suffix=" %"
    thousandSeparator
    isAllowed={({ floatValue, value }) => (floatValue >= 0 && floatValue <= 100) || value === ''}
    {...props}
  />
)

export default NumberInput
