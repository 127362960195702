import * as React from 'react'
import { uniqueId } from 'lodash-es'
import Field, { FieldDirection } from './field/field'
import Input, { InputPreset } from './input/input'
import { Validation } from './validations'
import { FormContext } from './smart-form'

type Props = {
  name: string

  label: string
  labelActions?: React.ReactNode

  hint?: string
  inlineHint?: string

  placeholder?: string
  type?: 'text' | 'email' | 'password'
  direction?: FieldDirection
  preset?: InputPreset
  autoComplete?: string

  validations?: Validation[]
  validationGroup?: string
  autoValidate?: boolean
  validateOnChangeWithError?: boolean
  hideErrors?: boolean

  icon?: React.ReactNode
  onIconClick?: () => void

  className?: string
};

const SmartInputField: React.FC<Props> = props => {
  const {
    name,
    label,
    type = 'text',
    placeholder,
    autoComplete,
    direction = FieldDirection.VERTICAL,
    preset = InputPreset.NORMAL,
    validations = [],
    validationGroup,
    autoValidate = false,
    icon,
    onIconClick,
    className,
    hideErrors = false,
    validateOnChangeWithError = true,
    labelActions,
    hint,
    inlineHint,
  } = props

  const { form, fields } = React.useContext(FormContext)
  const id = React.useMemo(() => uniqueId(`${form.id}-${name}-`), [])

  React.useEffect(() => {
    form.registerField(name, label, validations, validationGroup)
  }, [])

  React.useEffect(() => () => {
    form.unregisterField(name)
  }, [])

  const errors = fields[name]?.errors || []
  return (
    <Field
      position={direction}
      label={label}
      errors={errors}
      className={className}
      showTextErrors={!hideErrors}
      htmlFor={id}
      actions={labelActions}
      hint={hint}
      inlineHint={inlineHint}
    >
      <Input
        name={name}
        placeholder={placeholder}
        type={type}
        preset={preset}
        autoComplete={autoComplete}
        value={`${fields[name]?.value || ''}`}
        onChange={value => {
          form.setFieldValue(name, value)
          if (validateOnChangeWithError && errors.length > 0) form.validateField(name)
        }}
        hasError={errors.length > 0}
        onBlur={() => autoValidate && form.validateField(name)}
        icon={icon}
        onIconClick={onIconClick}
        id={id}
      />
    </Field>
  )
}
export default SmartInputField
