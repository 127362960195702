import * as React from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { css, Global } from '@emotion/core'
import styled from '@emotion/styled'

type Props = {
  isOpen: boolean
  title?: string
  width?: string
  titleMarginBottom?: number
  onClose: () => void
  minHeight?: number
};

type ContainerProps = {
  isOpen: boolean
}

type ModalProps = {
  isOpen: boolean
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(16, 42, 67, ${props => (props.isOpen ? '0.64' : '0')});
  z-index: 10000;
  transition: all ease-in-out 100ms;
  pointer-events: ${props => (props.isOpen ? 'auto' : 'none')};
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

type ModalScrollProps = {
  isOpen: boolean
}

const ModalScroll = styled.div<ModalScrollProps>`
  max-height: 100vh;
  overflow-y: ${props => props.isOpen ? 'auto' : 'hidden'};
  width: 100%;
  text-align: center;
  
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalContainer = styled.div<ModalProps>`
  position: relative;
  width: 100%;
  background-color: #fff;
  transition: all ease-in-out 200ms;
  z-index: 10001;
  border-radius: 4px;
  
  display: inline-flex;
  align-items: stretch;
  justify-content: stretch;
  margin: 5vh 0;
  padding: 40px;
  box-sizing: border-box;
  
  opacity: ${props => (props.isOpen ? '1' : '0')};
  transform: scale(${props => (props.isOpen ? '1' : '1.025')});
  
  text-align: left;
`

const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 0;
  z-index: 10001;
  cursor: pointer;
`

type TitleProps = {
  marginBottom?: number
}

const Title = styled.div<TitleProps>`
  font-size: 19px;
  text-align: center;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.colors.black100};
  width: 100%;
  margin-bottom: ${({ marginBottom }) => (marginBottom || 40)}px;
`

const ContentHolder = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
`

const ClickHandlerContainer = styled.div`
  max-width: 680px;
  // margin: 10vh auto;
  width: 100%;
  padding: 0 80px;
  
  @media screen and (max-width: 768px) {
    padding: 0 17px;
  }
`
const globalStyles = (open: boolean, paddingRight: number = 0) => css`
  body {
    overflow: ${open ? 'hidden' : 'auto'};
    padding-right: ${paddingRight}px;
  }
`

const Modal: React.FC<Props> = props => {
  const {
    isOpen, titleMarginBottom, children, onClose, title, minHeight,
  } = props

  const [showContent, setShowContent] = React.useState(false)
  const [styleShow, setStyleShow] = React.useState(false)

  React.useEffect(() => {
    if (!isOpen) {
      setStyleShow(false)
    } else {
      setShowContent(true)
    }
  }, [isOpen])

  React.useEffect(() => {
    if (isOpen) {
      setStyleShow(true)
    }
  }, [showContent])

  React.useEffect(() => {
    if (!styleShow) {
      const t = setTimeout(() => {
        setShowContent(false)
      }, 200)
      return () => clearTimeout(t)
    }
    return undefined;
  }, [styleShow])

  return (
    <>
      {showContent && (
        <Container isOpen={styleShow}>
          <Global styles={globalStyles(styleShow)} />
          <ModalScroll isOpen={styleShow}>
            <ClickHandlerContainer>
              <OutsideClickHandler onOutsideClick={onClose} display="flex">
                <ModalContainer isOpen={styleShow} style={{ minHeight }}>
                  <CloseButton onClick={onClose}>
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.2997 5.70997C17.9097 5.31997 17.2797 5.31997 16.8897 5.70997L11.9997 10.59L7.10973 5.69997C6.71973 5.30997 6.08973 5.30997 5.69973 5.69997C5.30973 6.08997 5.30973 6.71997 5.69973 7.10997L10.5897 12L5.69973 16.89C5.30973 17.28 5.30973 17.91 5.69973 18.3C6.08973 18.69 6.71973 18.69 7.10973 18.3L11.9997 13.41L16.8897 18.3C17.2797 18.69 17.9097 18.69 18.2997 18.3C18.6897 17.91 18.6897 17.28 18.2997 16.89L13.4097 12L18.2997 7.10997C18.6797 6.72997 18.6797 6.08997 18.2997 5.70997Z"
                        fill="#486581"
                      />
                    </svg>

                  </CloseButton>
                  <ContentHolder>
                    {title && (<Title marginBottom={titleMarginBottom}>{title}</Title>)}
                    {children}
                  </ContentHolder>
                </ModalContainer>
              </OutsideClickHandler>
            </ClickHandlerContainer>
          </ModalScroll>
        </Container>
      )
      }
    </>
  )
}

export default Modal
