import React from 'react'

const InstagramIcon = props => (
  <svg {...props} width="17" height="17" viewBox="0 0 17 17" fill="#616164" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.49756 5.67968C6.94444 5.67968 5.67697 6.94717 5.67697 8.50033C5.67697 10.0535 6.94444 11.321 8.49756 11.321C10.0507 11.321 11.3182 10.0535 11.3182 8.50033C11.3182 6.94717 10.0507 5.67968 8.49756 5.67968ZM16.9572 8.50033C16.9572 7.33229 16.9678 6.17483 16.9022 5.0089C16.8366 3.65466 16.5277 2.45276 15.5374 1.46247C14.545 0.470056 13.3453 0.163234 11.991 0.0976379C10.823 0.0320415 9.66558 0.0426216 8.49968 0.0426216C7.33166 0.0426216 6.17422 0.0320415 5.00832 0.0976379C3.6541 0.163234 2.45223 0.472172 1.46195 1.46247C0.46956 2.45488 0.162744 3.65466 0.0971486 5.0089C0.0315534 6.17694 0.0421333 7.3344 0.0421333 8.50033C0.0421333 9.66625 0.0315534 10.8258 0.0971486 11.9917C0.162744 13.346 0.471676 14.5479 1.46195 15.5382C2.45434 16.5306 3.6541 16.8374 5.00832 16.903C6.17634 16.9686 7.33378 16.958 8.49968 16.958C9.6677 16.958 10.8251 16.9686 11.991 16.903C13.3453 16.8374 14.5471 16.5285 15.5374 15.5382C16.5298 14.5458 16.8366 13.346 16.9022 11.9917C16.9699 10.8258 16.9572 9.66836 16.9572 8.50033ZM8.49756 12.8403C6.09593 12.8403 4.1577 10.902 4.1577 8.50033C4.1577 6.09865 6.09593 4.16038 8.49756 4.16038C10.8992 4.16038 12.8374 6.09865 12.8374 8.50033C12.8374 10.902 10.8992 12.8403 8.49756 12.8403ZM13.0152 4.99621C12.4544 4.99621 12.0016 4.54338 12.0016 3.98264C12.0016 3.42189 12.4544 2.96907 13.0152 2.96907C13.5759 2.96907 14.0287 3.42189 14.0287 3.98264C14.0289 4.11579 14.0028 4.24766 13.9519 4.37071C13.901 4.49376 13.8264 4.60556 13.7322 4.69971C13.6381 4.79386 13.5263 4.86852 13.4032 4.91939C13.2802 4.97027 13.1483 4.99637 13.0152 4.99621Z" />
  </svg>

)

export default InstagramIcon
