import styled from '@emotion/styled'
import { Link } from 'gatsby'

export const FooterContainer = styled.footer`
  flex: 0;
  color: #fff;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #1d1e21;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
  }
`

/*
Components
*/
export const Label = styled.p`
  color: white;
  font-size: 16px;
  line-height: 24px;
`

/*
 * Contacts
 */
export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 60px;
  @media screen and (min-width: 1024px) {
    margin-bottom: 0;
  }
  
  a:hover {
    svg {
      fill: #fff;
    }
  }
`

export const Logo = styled.img`
  width: 190px;
  height: 42px;
  margin-bottom: 34px;
`

export const PhoneLabel = styled(Label)`
  opacity: 0.6;
  margin-bottom: 13px;
`

export const Phone = styled.a`
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 46px;
`

/*
 * Menu
 */
export const MenuContainer = styled.div`
  max-width: 566px;
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  flex-grow: 3;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    margin-left: 20px;
  }
  @media screen and (min-width: 1024px) {
    flex-wrap: nowrap;
    margin-right: 20px;
  }
`

export const MenuBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
`

export const NormalLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 14px;
  line-height: 20px;
  transition: color 100ms ease-in-out;
  margin-bottom: 16px;
  &:hover {
    color: #00a095;
  }
`

export const NormalExternalLink = styled.a`
  text-decoration: none;
  color: white;
  font-size: 14px;
  line-height: 20px;
  transition: color 100ms ease-in-out;
  margin-bottom: 16px;
  &:hover {
    color: #00a095;
  }
`

export const BoldLink = styled(NormalLink)`
  font-weight: bold;
`

export const SectionTitle = styled.div`
  text-decoration: none;
  color: white;
  font-size: 14px;
  line-height: 20px;
  transition: color 100ms ease-in-out;
  margin-bottom: 16px;
  font-weight: bold;
`

export const BoldLinkWithExtra = styled(BoldLink)`
  margin-bottom: 52px;
`

/*
 * Info
 */
export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }

  @media screen and (min-width: 1024px) {
    width: 23%;
  }
`
export const InfoLabel = styled(Label)`
  font-size: 11px;
  line-height: 16px;
`
export const InfoLabelWithMargin = styled(InfoLabel)`
  margin-bottom: 30px;
`

export const LegalLink = styled(Link)`
  color: #ffffff;
`
