import * as React from 'react'
import { css, Global } from '@emotion/core'
import emotionReset from 'emotion-reset'

type Props = {};

const SiteHolder: React.FC<Props> = props => {
  return (
    <>
      <Global
        styles={css`
          ${emotionReset}
          body {
            font-family: Montserrat, Verdana, sans-serif;
            background-color: #f9f8f7;
          }
          
          * {
              margin: 0;
              padding: 0;
              box-sizing: border-box;
          }
        `}
      />
      {props.children}
    </>
  )
}

export default SiteHolder
