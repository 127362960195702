import React, { useState } from 'react'
import { Link } from 'gatsby'

import Button from 'components/common/button'
import Checkbox from 'components/common/checkbox'
import { Social } from 'components/common/social'
import DropDown from 'components/common/drop-down'
import Modal from 'components/common/modal/index'

import {
  ButtonsContainer,
  CheckboxContainer,
  Container,
  InnerContainer,
  LogoLink,
  LogoSection,
  Menu,
  MenuItem,
  MenuSection,
  Phone,
  SocialContainer,
} from './styles'

import ComCardLogo from 'assets/images/logo_green.svg'
import Waitlist from 'components/common/modal/content/waitlist'

const menuLinks = [
  {
    title: 'Cards',
    link: '/cards',
  },
  {
    title: 'Rewards',
    link: '/rewards',
  },
  {
    title: 'For Merchants',
    link: '/merchants',
  },
  {
    title: 'Resources',
    children: [
      {
        title: 'FAQ',
        href: '/faq',
      },
      {
        title: 'Support',
        href: 'https://support.comcard.us',
      },
      {
        title: 'Legal Agreements',
        link: '/legal/platform-agreement',
      },
    ],
  },
  {
    title: 'Company',
    children: [
      {
        title: 'About Us',
        link: '/about-us',
      },
      // {
      //   title: 'Ambassadors',
      //   link: '/ambassadors',
      // },
      // {
      //   title: 'Blog',
      //   link: '/blog',
      // },
      {
        title: 'Contact Us',
        link: '/contact-us',
      },
    ],
  },
]

type Props = {
  hideMenu?: boolean
  disableFixed?: boolean
}

const Header: React.FC<Props> = ({ hideMenu = false, disableFixed = false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)


  return (
    <Container disableFixed={disableFixed}>
      <Modal isOpen={isModalOpen} title="Join waitlist" onClose={() => setIsModalOpen(false)}>
        <Waitlist closeModal={() => setIsModalOpen(false)} />
      </Modal>
      <InnerContainer>
        <LogoSection>
          <LogoLink to="/">
            <img src={ComCardLogo} alt="ComCard logo" />
          </LogoLink>
          <CheckboxContainer>
            <Checkbox checked={isMenuOpen} onChange={() => setIsMenuOpen(!isMenuOpen)} />
          </CheckboxContainer>
        </LogoSection>
        <MenuSection isOpen={isMenuOpen} hide={hideMenu}>
          <Phone href="tel:+18449030903">(844) 903-0903</Phone>
          <Menu>
            {menuLinks.map(i => i.children ? <DropDown key={i.title} title={i.title} links={i.children} /> :
              <MenuItem key={i.title} to={i.link}>{i.title}</MenuItem>)}
          </Menu>
          <SocialContainer>
            <Social vertical={false} lighter />
          </SocialContainer>
          <ButtonsContainer>
            {/*<Button component={Link} to="/" target="_blank" preset={ButtonPreset.OUTLINE}>*/}
            {/*  Sign in*/}
            {/*</Button>*/}
            <Button component={Link} to="/" target="_blank" onClick={() => {
              setIsModalOpen(true)
              window.analytics.track('Open the waitlist modal', {
                location: 'Header',
              })
            }}>
              Join Waitlist
            </Button>
          </ButtonsContainer>
        </MenuSection>
      </InnerContainer>
    </Container>
  )
}

export default Header
