import React from 'react'
import styled from '@emotion/styled'
import { padding } from 'polished'
import { isFunction } from 'lodash-es'
import { InputPreset } from './types'

type InputContainerProps = {
  preset?: InputPreset
} & React.HTMLProps<HTMLDivElement>

export const InputContainer = styled.div<InputContainerProps>`
  display: ${props => (props.preset === InputPreset.NORMAL ? 'block' : 'inline-block')};
  position: relative;
`

type StyledInputProps = {
  hasIcon: boolean
  hasError?: boolean
  preset?: InputPreset
} & React.HTMLProps<HTMLInputElement>

export const StyledInput = styled.input<StyledInputProps>(({
  theme,
  hasIcon = false,
  hasError = false,
  preset = InputPreset.COMPACT,
  readOnly,
}) => [
  {
    color: theme.colors.black100,
    outline: 0,
    fontSize: 16,
    fontWeight: theme.fontWeight.bold,
    lineHeight: '16px',
    fontFamily: 'Montserrat, Verdana, sans-serif',
    ...padding(7, 16),
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 4,
    minWidth: 225,
    boxSizing: 'border-box',
    '::placeholder': {
      color: theme.colors.gray64,
    },
  },
  readOnly
    ? {
      cursor: 'pointer',
    }
    : {},
  hasIcon
    ? {
      paddingRight: 48,
    }
    : {},
  hasError
    ? {
      color: theme.colors.red100,
      '::placeholder': {
        color: theme.colors.red64,
      },
    }
    : {},
  {
    [InputPreset.COMPACT]: {},
    [InputPreset.NORMAL]: {
      ...padding(8, 0),
      borderBottom: '1px solid',
      fontWeight: theme.fontWeight.semiBold,
      borderRadius: 0,
      lineHeight: '21px',
      minWidth: 0,
      width: '100%',
      borderColor: hasError ? theme.colors.red100 : '#D9E2EC',
      transition: 'all 100ms ease-in-out',
      '&:focus': {
        borderColor: hasError ? theme.colors.red100 : theme.colors.gray100,
      },
    },
  }[preset],
])

export const IconContainer = styled.div`
  display: block;
  width: 16px;
  height: 16px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: ${props => (isFunction(props.onClick) ? 'pointer' : 'default')};

  color: ${props => props.theme.colors.gray64}
`

export const AutoCompleteContainer = styled.div`
  position: absolute;
  top: calc(100% + 3px);
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  z-index: ${props => props.theme.zIndex.select};
`
type AutoCompleteItemProps = {
  isActive?: boolean
}

export const AutoCompleteItem = styled.div<AutoCompleteItemProps>`
  background-color: ${props => (props.isActive ? props.theme.colors.green100 : props.theme.colors.white100)};
  color: ${props => (props.isActive ? props.theme.colors.white100 : props.theme.colors.black100)};
  padding: 6px 10px;  
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  &:hover {
    cursor: pointer;
  }
`
