import React from 'react'
import ComCardLogo from '../../assets/images/logo_green.svg'

import {
  BoldLink,
  BoldLinkWithExtra,
  ContactsContainer,
  ContentContainer,
  FooterContainer,
  InfoContainer,
  InfoLabel,
  InfoLabelWithMargin,
  LegalLink,
  Logo,
  MenuBlock,
  MenuContainer,
  NormalExternalLink,
  NormalLink,
  Phone,
  PhoneLabel,
  SectionTitle,
} from './styles'
import { Container } from '../common/styles'
import { Social } from '../common/social'

const Footer: React.FC = () => (
  <FooterContainer>
    <Container>
      <ContentContainer>
        <ContactsContainer>
          <Logo src={ComCardLogo} />
          <PhoneLabel>Сall us if you have questions</PhoneLabel>
          <Phone href="tel:+18449030903">(844) 903 0903</Phone>

          <Social />
        </ContactsContainer>

        <MenuContainer>
          <MenuBlock>
            <SectionTitle>Company</SectionTitle>
            <NormalLink to="/about-us">About Us</NormalLink>
            {/*<NormalLink to="/company">Ambassadors</NormalLink>*/}
            {/*<NormalLink to="/blog">Blog</NormalLink>*/}
            <NormalLink to="/contact-us">Contact Us</NormalLink>
          </MenuBlock>

          <MenuBlock>
            <BoldLink to="/cards">Cards</BoldLink>
            <BoldLink to="/rewards">Rewards</BoldLink>
            <BoldLink to="/merchants">For Merchants</BoldLink>
          </MenuBlock>

          <MenuBlock>

            {/*<NormalLink to="/company">Interior design</NormalLink>*/}
            {/*<NormalLink to="/company">Construction</NormalLink>*/}
            <SectionTitle>Resources</SectionTitle>
            <NormalLink to="/faq">FAQ</NormalLink>
            <NormalLink to="/legal/platform-agreement">Legal</NormalLink>
            <NormalExternalLink href="https://support.comcard.us">Support Center</NormalExternalLink>
          </MenuBlock>
        </MenuContainer>

        <InfoContainer>
          <InfoLabelWithMargin>
            © 2020 ComCard, Inc. All rights reserved.
          </InfoLabelWithMargin>
          <InfoLabel>
            ComCard card is issued by Sutton Bank, Member FDIC, pursuant to license by Mastercard International. Terms
            and conditions apply. See the <LegalLink to="/legal/platform-agreement">ComCard Platform
            Agreement</LegalLink> for details.
          </InfoLabel>
        </InfoContainer>
      </ContentContainer>
    </Container>
  </FooterContainer>
)

export default Footer
