import styled from '@emotion/styled'
import { isFunction } from 'lodash-es'
import React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { padding } from 'polished'
import { InputPreset } from '../input/input'

type StyledNumberInputProps = {
  preset?: InputPreset
  hasError?: boolean
} & NumberFormatProps

export const NumberInputStyled = styled(NumberFormat)<StyledNumberInputProps>(({
  theme,
  preset = InputPreset.COMPACT,
  readOnly,
  hasError = false,
}) => [
  {
    color: theme.colors.black100,
    outline: 0,
    fontSize: 16,
    fontFamily: 'Montserrat, Verdana, sans-serif',
    fontWeight: theme.fontWeight.bold,
    lineHeight: '16px',
    ...padding(7, 16),
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: 4,
    minWidth: 225,
    boxSizing: 'border-box',
    '::placeholder': {
      color: theme.colors.gray64,
    },
  },
  readOnly
    ? {
      cursor: 'pointer',
    }
    : {},
  hasError
    ? {
      color: theme.colors.red100,
      '::placeholder': {
        color: theme.colors.red64,
      },
    }
    : {},
  {
    [InputPreset.COMPACT]: {},
    [InputPreset.NORMAL]: {
      ...padding(8, 0),
      borderBottom: '1px solid #D9E2EC',
      borderColor: hasError ? theme.colors.red100 : '#D9E2EC',
      fontWeight: theme.fontWeight.semiBold,
      borderRadius: 0,
      lineHeight: '21px',
      minWidth: 0,
      width: '100%',
    },
  }[preset],
])

export const IconContainer = styled.div`
  display: block;
  width: 16px;
  height: 16px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  cursor: ${props => (isFunction(props.onClick) ? 'pointer' : 'default')};

  color: ${props => props.theme.colors.gray64}
`

type InputContainerProps = {
  preset?: InputPreset
  hasIcon?: boolean
  hasError?: boolean
} & React.HTMLProps<HTMLDivElement>

export const InputContainer = styled.div<InputContainerProps>(
  props => [
    {
      display: props.preset === InputPreset.NORMAL ? 'block' : 'inline-block',
      position: 'relative',
    },
    props.hasError ? {
      input: {
        color: props.theme.colors.red100,
        '::placeholder': {
          color: props.theme.colors.red64,
        },
      },
    } : {},
    props.hasIcon ? {
      input: {
        paddingRight: 48,
      },
    } : {},
  ],
)
