import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import Arrow from 'assets/images/icons/arrow.svg'

const DropDownContainer = styled.div`
  position: relative;
  margin-right: 32px;
  transition: all 100ms ease-in-out;
  
  @media screen and (max-width: 1280px) {
    margin-right: 24px;
  }
  
  @media screen and (max-width: 1000px) {
    width: 100vw;
    text-align: center;
    margin-right: 0;
    margin-top: 16px;
    
    div {
      display: block !important;
    }
  }
      
  div { display: none; }
    
  :hover {
    color: #00a095;
    div { display: block; }
  }
`

type Props = {
  isOpen?: boolean
}

const DropDownMenu = styled.div<Props>`
    position: absolute;
    z-index: 5;
    padding-top: 32px;
    border-radius: 4px;
    
    @media screen and (max-width: 1000px) {
      position: initial;     
      width: 100%;
      max-height: ${props => props.isOpen ? 160 : 0}px;
      overflow: ${props => props.isOpen ? 'auto' : 'hidden'};
      height: 160px;
      padding-top: ${props => props.isOpen ? 16 : 0}px;
      transition: 0.2s all linear;
    }
`

const DropDownMenuItem = styled(Link)`
  display: block;
  height: 48px;
  padding: 0 22px;
  font-size: 14px;
  line-height: 48px;
  font-weight: 500;
  background: #F9F8F7;
  color: #1d1e21;
  text-decoration: none;
  
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  
  :hover {
   color: #00a095;
   background: #E8E5E2;
  }
  
   @media screen and (max-width: 1000px) {
     padding-left: 0;
     padding-right: 0;
     font-weight: 500;
     color: #1d1e21;
   }
`

const DropDownMenuItemA = styled.a`
  display: block;
  height: 48px;
  padding: 0 22px;
  font-size: 14px;
  line-height: 48px;
  font-weight: 500;
  background: #F9F8F7;
  color: #1d1e21;
  text-decoration: none;
  
  transition: all 0.2s ease-in-out;
  
  :hover {
   color: #00a095;
   background: #E8E5E2;
   cursor: pointer;
  }
  
   @media screen and (max-width: 1000px) {
     padding-left: 0;
     padding-right: 0;
     font-weight: 500;
     color: #1d1e21;
   }
`


const Title = styled.span`
  font-weight: 500;
  cursor: default;
  
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
`
const Image = styled.img<Props>`
  display: none;
  
  @media screen and (max-width: 1000px) {
    position: absolute;
    top: 9px;
    display: inline-block;
    
    transform: ${props => props.isOpen && 'rotate(-90deg)'};
  }
  
  margin-left: 12px;
  transition: 0.2s all linear;
`


const DropDown = ({ title, links }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onClick = () => {
    if (window.innerWidth <= 1000) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <DropDownContainer>
      <Title onClick={onClick}>{title}</Title>
      <Image src={Arrow} isOpen={!isOpen} />
      <DropDownMenu isOpen={isOpen}>{links.map(i => {
        if (i.href) {
          return (
            <DropDownMenuItemA key={i.title} href={i.href}>{i.title}</DropDownMenuItemA>
          )
        } else {
          return (
            <DropDownMenuItem key={i.title} to={i.link}>{i.title}</DropDownMenuItem>
          )
        }
      })}</DropDownMenu>
    </DropDownContainer>
  )
}

export default DropDown
