import styled from '@emotion/styled'
import { Link } from 'gatsby'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1680px;
  padding: 0 100px;
  
  @media screen and (max-width: 768px) {
    padding: 0 17px;
  }
`

export const BlockTitle = styled.h2`
  font-size: 32px;
  line-height: 1.5;
  color: #000;
  font-weight: bold;
  
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`

export const ReadMoreLink = styled(Link)`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #00a095;
  text-decoration: none;

  ::after {
    content: ' >';
  }
`

export const SocialContainer = styled.div`
  display: flex;
  align-content: space-between;
  margin: -14px;
`

export const SocialContainerVertical = styled(SocialContainer)`
  flex-direction: column;
  justify-content: center;
`

export const SocialContainerHorizontal = styled(SocialContainer)``

export type Props = {
  lighter: boolean
}

export const StyledLink = styled.a<Props>`
  margin: 14px;
  text-align: center;
  pointer-events: auto;
  
  svg {
    fill: ${({ lighter }) => lighter && '#6bcbc4'};
    transition: fill ease-in-out 100ms;
  }
  
  &:hover {
    svg {
      fill: ${props => props.theme.colors.green100};
    }
  }
`

export const Text = styled.p`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  color: #666668;
`

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const WidthLimiter = styled.div`
  max-width: 1560px;
`
