import React from 'react'
import styled from '@emotion/styled'
import SmartInputField from 'components/forms/smart-input-field'
import SmartNumberInputField from 'components/forms/smart-number-input-field'
import SmartForm from 'components/forms/smart-form'
import { ValidationType } from 'components/forms/validations'
import Button from 'components/common/button'
import SmartSelectField from 'components/forms/smart-select-field'
import { AlertsContext } from 'components/alerts'

import SentSVG from 'assets/images/sent.svg'
import { default as axios } from 'axios'
import SmartCheckboxField from 'components/forms/smart-checkbox-field'
import CompanyType from '../../enums/company-type'


const Input = styled(SmartInputField)` margin-bottom: 32px;`
const Phone = styled(SmartNumberInputField)` margin-bottom: 32px;`
const Industry = styled(SmartSelectField)`margin-bottom: 32px;`
const SubTitle = styled.div`font-size: 14px; margin-bottom: 20px;line-height: 1.5;color: #5e5e5e;`
const Actions = styled.div`display: flex;flex-direction: row;justify-content: flex-end;`
const Sent = styled.div`text-align: center;`
const SentTitle = styled.div`font-size: 34px;line-height: 1;font-weight: bold;color: #000000;margin-top: 60px;`
const SentSubTitle = styled.div`font-size: 14px;line-height: 1.5;color: #646363;margin-top: 25px;`
const Checkbox = styled(SmartCheckboxField)`margin-bottom: 30px;`

type Props = {
  closeModal: () => void
  defaultIndustry?: CompanyType
}

const LinkedInConverter = styled.img`
  display: none;
`

const IndustryTiles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin-bottom: 25px;
`

const IndustryTile = styled.label`
  border-radius: 4px;
  border: 1px solid #6bcbc4;
  cursor: pointer;
  
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  
  span {
    flex: 1;
    padding: 15px;
    font-size: 14px;
    color: ${props => props.theme.colors.black100};
    text-align: center;
  }
  
  &:last-of-type {
    grid-column: 1 / span 2;
  }
  
  input {
    display: none;
    
    &:checked + span {
      background: ${props => props.theme.colors.green100};
      color: #ffffff;
    }
  }
`

const IndustryLabel = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.colors.black100};
  margin-bottom: 5px;
`

const Waitlist: React.FC<Props> = props => {
  const { defaultIndustry = CompanyType.CONSTRUCTION } = props
  const [isSending, setSending] = React.useState(false)
  const [industry, setIndustry] = React.useState(defaultIndustry)

  const alertCtx = React.useContext(AlertsContext)

  return (
    <SmartForm onSubmit={(e, valid, fields, form) => {
      e.preventDefault()
      e.stopPropagation()

      if (!valid || isSending) return

      setSending(true)
      axios.post(`${process.env.GATSBY_WEBSITE_API}/waitlist/cardholders`, {
        name: fields.name.value,
        companyName: fields.company_name.value,
        type: industry,
        email: fields.email.value,
      }).then(() => {
        form.resetForm()
        setSending(false)
        props.closeModal()
        alertCtx.showAlert(
          (<Sent>
            <img src={SentSVG} alt="Sent" />
            <SentTitle>You are in!</SentTitle>
            <SentSubTitle>We added you to our waitlist. We will keep you updated.</SentSubTitle>
            <LinkedInConverter height="1" width="1"
                               src="https://px.ads.linkedin.com/collect/?pid=2215836&conversionId=2421564&fmt=gif" />
          </Sent>),
        )
        window.analytics.track('Submitted to waitlist', {})
      }).catch(e => {
        setSending(false)
        alert('Error while sending request. Try again later.')
        window.analytics.track('Error while submitting to waitlist failed', {})
      })
    }}>
      <SubTitle>Please fill this form and product specialist will contact prior to launch</SubTitle>

      <Input name="name" label="Your name" validations={[{ type: ValidationType.REQUIRED }]} />
      <Input name="company_name" label="Company name" validations={[{ type: ValidationType.REQUIRED }]} />
      <Input
        name="email"
        label="Email"
        validations={[{ type: ValidationType.REQUIRED }, { type: ValidationType.EMAIL }]}
      />

      <IndustryLabel>What industry you in?</IndustryLabel>
      <IndustryTiles>
        <IndustryTile onClick={() => setIndustry(CompanyType.CONSTRUCTION)}>
          <input type="radio" checked={industry == CompanyType.CONSTRUCTION} />
          <span>Construction</span>
        </IndustryTile>
        <IndustryTile onClick={() => setIndustry(CompanyType.INTERIOR_DESIGN)}>
          <input type="radio" checked={industry == CompanyType.INTERIOR_DESIGN} />
          <span>Interior Design</span>
        </IndustryTile>
        <IndustryTile onClick={() => setIndustry(CompanyType.MANUFACTURING)}>
          <input type="radio" checked={industry == CompanyType.MANUFACTURING} />
          <span>Manufacturing</span>
        </IndustryTile>
        <IndustryTile onClick={() => setIndustry(CompanyType.FASHION)}>
          <input type="radio" checked={industry == CompanyType.FASHION} />
          <span>Fashion</span>
        </IndustryTile>
        <IndustryTile onClick={() => setIndustry(CompanyType.OTHER)}>
          <input type="radio" checked={industry == CompanyType.OTHER} />
          <span>Other</span>
        </IndustryTile>
      </IndustryTiles>

      <Checkbox
        name="privacy"
        label={<>By signing up, I confirm that I have read and understood the <a href="/legal/privacy-policy"
                                                                                 target="_blank">ComCard Privacy
          Policy</a>.</>}
        validations={[{ type: ValidationType.CHECKBOX, customError: 'Please agree with our Privacy Policy' }]}
      />

      <Actions>
        <Button isLoading={isSending}>Join</Button>
      </Actions>
    </SmartForm>
  )
}

export default Waitlist
