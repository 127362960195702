export const createContactUsSchema = () => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'ComCard',
  url: 'https://comcard.us',
  logo: 'https://comcard.us/logo_512.png',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+1 844 903-0903',
      contactType: 'customer service',
      contactOption: ['TollFree', 'HearingImpairedSupported'],
      areaServed: ['US', 'CA'],
      email: 'mailto:support@comcard.us',
      availableLanguage: 'en',
    },
    {
      '@type': 'ContactPoint',
      telephone: '+1 844 903-0903',
      contactType: 'merchant sales',
      contactOption: ['TollFree', 'HearingImpairedSupported'],
      areaServed: ['US', 'CA'],
      email: 'mailto:merchant@comcard.us',
      availableLanguage: 'en',
    },
  ],
  sameAs: [
    'https://www.facebook.com/comcard/',
    'https://www.instagram.com/comcard_us/',
    'https://twitter.com/comcard_us',
    'https://www.linkedin.com/company/comcard/',
  ],
})

export const createBlogSchema = (
  location,
  title,
  description,
  imageURL,
  datePublished,
) => ({
  '@context': 'https://schema.org',
  '@type': 'Article',
  mainEntityOfPage: {
    '@type': 'WebPage',
    '@id': `${location}`,
  },
  headline: `${title}`,
  description: `${description}`,
  image: {
    '@type': 'ImageObject',
    url: `${imageURL}`,
    width: 1200,
    height: 630,
  },
  publisher: {
    '@type': 'Organization',
    name: 'ComCard',
    logo: {
      '@type': 'ImageObject',
      url: 'https://comcard.us/logo_512.png',
      width: 512,
      height: 113,
    },
  },
  datePublished: `${datePublished}`,
  dateModified: `${datePublished}`,
})

export const createHomeSchema = () => ({
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: 'ComCard, Inc.',
  url: 'https://comcard.us',
  logo: 'https://comcard.us/logo_512.png',
  foundingDate: '2019',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+1-(844)-903-0903',
      contactType: 'sales and support',
    },
  ],
  founders: [
    {
      '@type': 'Person',
      name: 'Chingis Akatayev',
    },
    {
      '@type': 'Person',
      name: 'Alex Bondarevskyi',
    },
  ],
  sameAs: [
    'https://www.facebook.com/comcard/',
    'https://www.instagram.com/comcard_us/',
    'https://twitter.com/comcard_us',
    'https://www.linkedin.com/company/comcard/',
  ],
  description: 'ComCard is a Los Angeles based company that provides a corporate card that uniquely connects customers and merchants, rewarding both under a single unified network.',
})

export const createCardSchema = () => ({
  '@context': 'http://schema.org',
  '@graph': [
    {
      '@type': 'CreditCard',
      name: 'ComCard Corporate Card',
      brand: 'ComCard',
      description: 'ComCard is a corporate card that allows companies to increase savings while shopping professionally and manage expenses in one place.',
      requiredCollateral: 'No personal guarantee',
      offers: {
        '@type': 'Offer',
        offeredBy: {
          '@type': 'BankOrCreditUnion',
          name: 'ComCard, Inc.',
          telephone: '+1 844 903-0903',
          priceRange: '$',
          image: 'https://comcard.us/logo_512.png',
        },
        priceSpecification: {
          '@type': 'UnitPriceSpecification',
          price: '0',
          priceCurrency: 'USD',
        },
      },
      annualPercentageRate: '0',
    },
  ],
})

export const createFAQSchema = (items: {
  question: string
  answer: string
  publishingDate: string
}[]) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: items.map(i => ({
    '@type': 'Question',
    name: i.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: i.answer,
      dateCreated: i.publishingDate,
      url: 'https://comcard.us/faq/',
      author: {
        '@type': 'Organization',
        name: 'ComCard',
      },
    },
  })),
})
