import React from 'react'
import Header from '../components/header'
import SiteHolder from '../components/site-holder'
import Footer from '../components/footer/footer'
import styled from '@emotion/styled'
import { defaultTheme } from '../styles/themes'
import { ThemeProvider } from 'emotion-theming'
import Helmet from 'react-helmet'
import Alerts from '../components/alerts'

const SiteExpander = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const SiteContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

type Props = {
  title?: string
  description?: string
  indexPage?: boolean
  ogImage?: string
  ogTitle?: string
  ogDescription?: string
  helmetChildren?: React.ReactNode | [React.ReactNode]
  location?: any
  hideHeaderMenu?: boolean
  disableFixedHeader?: boolean
  hideHeader?: boolean
}

const Layout: React.FC<Props> = (props) => {
  const {
    title = 'ComCard | Corporate Card Made To Fit Your Needs',
    description = 'Receive no personal liability card with high credit limits that will help you save time and money.',
    indexPage = true,
    ogImage = 'https://comcard.us/og_image.jpg',
    ogTitle = title,
    ogDescription = description,
    helmetChildren,
    hideHeaderMenu,
    disableFixedHeader,
    children,
    hideHeader = false
  } = props

  return (
    <ThemeProvider theme={defaultTheme}>
      <Alerts>
        <SiteHolder>
          <SiteExpander>
            <Helmet
              title={title}
              meta={[
                {
                  name: 'description',
                  content: description,
                },
                {
                  name: 'robots',
                  content: indexPage ? 'index,follow' : 'noindex,follow',
                },
                {
                  property: 'og:image',
                  content: ogImage,
                },
                {
                  property: 'og:title',
                  content: ogTitle,
                },
                {
                  property: 'og:description',
                  content: ogDescription,
                },
                {
                  property: 'og:type',
                  content: 'website',
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
                {
                  name: 'twitter:site',
                  content: '@comcard_us',
                },
                {
                  name: 'twitter:title',
                  content: ogTitle,
                },
                {
                  name: 'twitter:description',
                  content: ogDescription,
                },
                {
                  name: 'twitter:image',
                  content: ogImage,
                },
              ]}
              link={[
                {
                  rel: 'apple-touch-icon',
                  sizes: '180x180',
                  href: '/apple-touch-icon.png',
                },
                {
                  rel: 'icon',
                  sizes: '32x32',
                  href: '/favicon-32x32.png',
                  type: 'image/png',
                },
                {
                  rel: 'icon',
                  sizes: '16x16',
                  href: '/favicon-16x16.png',
                  type: 'image/png',
                },
                {
                  rel: 'manifest',
                  href: '/site.webmanifest',
                },
              ]}
            >
              {helmetChildren}
            </Helmet>
            {!hideHeader && <Header hideMenu={hideHeaderMenu} disableFixed={disableFixedHeader} />}
            <SiteContent>
              {children}
            </SiteContent>
            <Footer />
          </SiteExpander>
        </SiteHolder>
      </Alerts>
    </ThemeProvider>
  )
}

export default Layout
