import * as React from 'react'
import SelectRaw, { components } from 'react-select'
import { padding } from 'polished'
import { isString, omit } from 'lodash-es'
import { defaultTheme } from '../../../styles/themes'

export enum SelectPreset {
  COMPACT,
  NORMAL
}

interface Props<T> {
  id?: string
  name?: string
  data: T[]
  valueField?: string
  labelField?: string
  menuSide?: 'left' | 'right'
  onChange: (value: T | ReadonlyArray<T>) => void
  width?: number | string
  maxMenuWidth?: number | string
  maxMenuHeight?: number
  placeholder?: string
  isSearchable?: boolean
  value?: T | string
  hasError?: boolean
  preset?: SelectPreset
}

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <svg width="8" height="6" viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.4134 1.19332L4.00006 3.77999L6.58673 1.19332C6.84673 0.933322 7.26673 0.933322 7.52673 1.19332C7.78673 1.45332 7.78673 1.87332 7.52673 2.13332L4.46673 5.19332C4.20673 5.45332 3.78673 5.45332 3.52673 5.19332L0.466728 2.13332C0.206729 1.87332 0.206729 1.45332 0.466728 1.19332C0.726728 0.939988 1.1534 0.933322 1.4134 1.19332Z"
        fill="currentColor"
      />
    </svg>
  </components.DropdownIndicator>
)

// eslint-disable-next-line react/prefer-stateless-function
class Select<T> extends React.Component<Props<T>> {
  render() {
    const {
      id,
      name,
      data,
      valueField = 'value',
      labelField = 'label',
      menuSide = 'left',
      width = 100,
      maxMenuWidth = 250,
      maxMenuHeight = 200,
      placeholder,
      isSearchable = false,
      onChange,
      value,
      hasError = false,
      preset = SelectPreset.COMPACT,
      ...props
    } = this.props

    let internalValue: T

    if (isString(value)) {
      internalValue = data.find(i => i[valueField] === value)
    } else {
      internalValue = value
    }

    return (
      <SelectRaw
        {...props}
        id={id}
        name={name}
        isSearchable={isSearchable}
        placeholder={placeholder}
        styles={{
          control: defaultProps => {
            const styles = {
              ...defaultProps,
              minHeight: 30,
              width,
              border: 0,
              boxShadow: 'none',
              borderRadius: 4,
            }

            if (preset === SelectPreset.NORMAL) {
              styles.width = 'auto'
              styles.borderBottom = '1px solid #D9E2EC !important'
              styles.borderRadius = 0
              styles.minHeight = 0
            }

            return styles
          },
          indicatorSeparator: () => ({
            display: 'none',
          }),
          singleValue: defaultProps => ({
            ...omit(defaultProps, ['position', 'top', 'transform']),
            fontSize: 16,
            lineHeight: preset === SelectPreset.NORMAL ? '21px' : 1,
            fontWeight: preset === SelectPreset.NORMAL
              ? defaultTheme.fontWeight.semiBold
              : defaultTheme.fontWeight.bold,
            margin: 0,
            color: hasError ? defaultTheme.colors.red100 : defaultTheme.colors.black100,
          }),
          dropdownIndicator: defaultProps => ({
            ...defaultProps,
            ...padding(5, 16),
            color: preset === SelectPreset.NORMAL
              ? defaultTheme.colors.black100
              : defaultTheme.colors.gray64,
          }),
          valueContainer: defaultProps => {
            const styles = {
              ...defaultProps,
              paddingLeft: 16,
              paddingRight: 0,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }

            if (preset === SelectPreset.NORMAL) {
              styles.paddingLeft = 0
              styles.paddingTop = 8
              styles.paddingBottom = 8
              styles.lineHeight = '21px'
            }

            return styles
          },
          placeholder: defaultProps => ({
            ...omit(defaultProps, ['position', 'top', 'transform']),
            fontSize: 13,
            color: hasError ? defaultTheme.colors.red64 : defaultTheme.colors.gray64,
            fontWeight: preset === SelectPreset.NORMAL
              ? defaultTheme.fontWeight.normal
              : defaultTheme.fontWeight.bold,
            margin: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 'calc(100% - 1px)',
          }),
          option: (defaultProps, state) => {
            const dynamic = {
              backgroundColor: defaultTheme.colors.white100,
              color: defaultTheme.colors.black100,
              cursor: 'pointer',
              '&active': {
                backgroundColor: defaultTheme.colors.white100,
                color: defaultTheme.colors.black100,
              },
            }
            if (state.isFocused) {
              dynamic.backgroundColor = defaultTheme.colors.green100
              dynamic.color = defaultTheme.colors.white100
              dynamic['&:active'] = {
                backgroundColor: defaultTheme.colors.green100,
                color: defaultTheme.colors.white100,
              }
            }
            if (state.isDisabled) {
              dynamic.backgroundColor = defaultTheme.colors.white100
              dynamic.color = defaultTheme.colors.gray64
              dynamic['&:active'] = {
                backgroundColor: defaultTheme.colors.white100,
                color: defaultTheme.colors.gray64,
              }
              dynamic.cursor = 'default'
            }
            return {
              ...defaultProps,
              ...dynamic,
              fontSize: 14,
              fontWeight: preset === SelectPreset.NORMAL
                ? defaultTheme.fontWeight.normal
                : defaultTheme.fontWeight.bold,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              padding: '10px 15px',
            }
          },
          menu: defaultProps => ({
            ...defaultProps,
            width: 'auto',
            maxWidth: maxMenuWidth,
            minWidth: '100%',
            [menuSide]: 0,
            boxShadow: '0 3px 8px rgba(0, 0, 0, 0.25)',
            border: 0,
            overflow: 'hidden',
          }),
          menuList: defaultProps => ({
            ...defaultProps,
            padding: 0,
          }),
        }}
        options={data}
        components={{
          DropdownIndicator,
        }}
        getOptionValue={option => option[valueField]}
        getOptionLabel={option => option[labelField]}
        onChange={val => onChange(val)}
        value={internalValue}
        maxMenuHeight={maxMenuHeight}
      />
    )
  }
}

export default Select
