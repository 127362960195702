import React from 'react'
import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

type InnerContainerProps = {
  position: 'vertical' | 'horizontal'
} & React.HTMLProps<HTMLDivElement>

export const InnerContainer = styled.div<InnerContainerProps>(props => [
  {
    display: 'flex',
  },
  props.position === 'vertical'
    ? {
      flexDirection: 'column',
    }
    : {
      flexDirection: 'row',
      alignItems: 'center',
    },
])

type LabelProps = {
  position: 'vertical' | 'horizontal'
  width?: number
  hasError?: boolean
} & React.HTMLProps<HTMLLabelElement>

export const Label = styled.label<LabelProps>`
  font-size: 14px;
  line-height: ${props => (props.position === 'vertical' ? '21px' : 'auto')};
  color: ${props => (props.position === 'vertical' ? props.theme.colors.black100 : props.theme.colors.gray64)};
  margin-right: ${props => (props.position === 'horizontal' ? 16 : 0)}px;
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
`

export const FieldContainer = styled.div``

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ActionsContainer = styled.div`
  margin-left: 10px;
  display: inline-flex;
  flex-direction: row;
`

export const Errors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3px;
`

export const Error = styled.div`
  color: ${props => props.theme.colors.red100};
  font-size: 12px;
  line-height: 19px;
  font-weight: ${props => props.theme.fontWeight.normal};
`
