import styled from '@emotion/styled'
import { Link } from 'gatsby'

type ContainerProps = {
  disableFixed?: boolean
}

export const Container = styled.header<ContainerProps>`
  position: ${props => props.disableFixed ? 'static' : 'sticky'}; 
   top: 0; //TODO replace on sticky, because content move under the header
  z-index: 1000; 
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #F9F8F7;
  
  transition: all 0.2s linear;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px 72px;
  font-size: 16px;
  width: 100%;
  max-width: 1680px;
  
  @media screen and (max-width: 1366px) {
    padding-left: 64px;
    padding-right: 64px;
  }
  
  @media screen and (max-width: 1280px) {
    padding-left: 56px;
    padding-right: 56px;
    font-size: 16px;
  }
  
   @media screen and (max-width: 1200px) {
    font-size: 14px;
   }
  
  @media screen and (max-width: 1150px) {
    padding-left: 20px;
    padding-right: 20px;  
  }
  
  @media screen and (max-width: 1000px) {
    flex-direction: column;    
  }
  
   @media screen and (max-width: 768px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
`

export const LogoSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  @media screen and (max-width: 1000px) {
    justify-content: space-between;
    width: 100%;
  }
`

export const LogoLink = styled(Link)`
  font-size: 0;
`
export const Phone = styled.a`
  height: max-content;
  margin-right: auto;
  color: #1d1e21;
  font-size: inherit;
  line-height: 24px;
  text-decoration: none;
  font-weight: 500;
  transition: color ease-in-out 100ms;

  &:hover {
    color: ${props => props.theme.colors.green100};
  }
  
  @media screen and (max-width: 1000px) {
    order: 1;
    margin-top: 55px;
    margin-right: initial;
  }
`
type Props = {
  isOpen: boolean
  hide: boolean
}

export const MenuSection = styled.div<Props>`
  display: ${props => props.hide ? 'none' : 'flex'};
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
   
  margin-left: 36px;
  padding-left: 25px;
  border-left: 1px solid #c4c4c4;
   
    
  @media screen and (max-width: 1366px) {
    margin-left: 30px;
  }
  
  @media screen and (max-width: 1100px) {
    margin-left: 10px;
    padding-left: 10px;
  }
  
  @media screen and (max-width: 1000px) {
    visibility: ${props => props.isOpen ? 'auto' : 'hidden'};
    opacity: ${props => props.isOpen ? 1 : 0};
    position: absolute;
    top: 68px;
    z-index: ${props => props.isOpen ? 5000 : -5};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 0;
    padding: 40px 17px 32px;
    font-size: 16px;
    background: #F9F8F7;
    border-left: none;
  }
  
  @media screen and (max-width: 768px) {
    top: 48px;
  }
`

export const SocialContainer = styled.div`
  order: 1;
  margin: 31px 0;
  
  @media screen and (min-width: 1001px) {
    display: none;
  }
`

export const Menu = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
  
  @media screen and (max-width: 1280px) {
    margin-right: 12px;
  }
  
  @media screen and (max-width: 1100px) {
    margin-right: 0;
  }
  
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    margin-right: 0;
  }
`

export const MenuItem = styled(Link)`
  font-weight: 500;
  font-size: inherit;
  line-height: 24px;
  color: #1d1e21;

  display: inline-block;
  margin-right: 32px;
  text-decoration: none;
  
  transition: color 100ms ease-in-out;
  &:hover {
    color: #00a095;
  }
  
  // &:last-of-type {
  //   margin-right: 0;
  // }
  
  @media screen and (max-width: 1280px) {
    margin-right: 24px;
  }
  
  @media screen and (max-width: 1000px) {
    margin-right: 0;
    margin-top: 16px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  order: 2; 
  width: inherit;
  
  & > :first-of-type {
    margin-right: 16px;
  }
  
  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    
    & > :first-of-type {
      margin-top: 8px;
      margin-right: 0;
    }
  }
`
export const CheckboxContainer = styled.div`
  display: none;

  @media screen and (max-width: 1000px) {
    display: block;
  }
`
