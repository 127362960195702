import styled from '@emotion/styled'
import React from 'react'
import { keyframes } from '@emotion/core'

export enum ButtonPreset {
  PRIMARY,
  OUTLINE
}

export enum ButtonSize {
  SMALL,
  MEDIUM,
  LARGE
}

type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  preset?: ButtonPreset
  size?: ButtonSize
  isLoading?: boolean
}

const LoadingAnimation = keyframes`
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
`

const LoadingAnimationBefore = keyframes`
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
`

const LoadingAnimationAfter = keyframes`
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
`


const Loading = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: ${LoadingAnimation} 1s infinite linear;
  
  left: calc(50% - 5px);
  top: calc(50% - 5px);

  &:before, &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &:before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: ${LoadingAnimationBefore} 1s infinite linear;
  }

  &:after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    animation: ${LoadingAnimationAfter} 1s infinite linear;
  }
`

const dynamicStyles = ({ size = ButtonSize.SMALL, preset = ButtonPreset.PRIMARY, isLoading = false, theme }) => ([
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'inherit',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.5,
    textDecoration: 'none',
    boxSizing: 'border-box',
    border: 'none',
    borderRadius: '4px',
    transition: 'all 100ms ease-in-out',
    cursor: 'pointer',
    outline: 0,
    position: 'relative',
    span: {
      transition: 'all 100ms ease-in-out',
    },
  },
  {
    [ButtonPreset.PRIMARY]: {
      color: '#ffffff',
      background: theme.colors.green100,
      boxShadow: '0 5px 12px rgba(0, 160, 149, 0.4)',
      '&:hover': {
        backgroundColor: isLoading ? theme.colors.green100 : '#6bcbc4',
      },
      '.loader': {
        background: '#ffffff',
        '&:before': {
          background: '#ffffff',
        },
        '&:after': {
          background: '#ffffff',
        },
      },
    },
    [ButtonPreset.OUTLINE]: {
      backgroundColor: 'transparent',
      color: '#00a095',
      border: '2px solid #00a095',
      '&:hover': {
        borderColor: isLoading ? '#00a095' : '#6bcbc4',
        color: '#6bcbc4',
      },
      '.loader': {
        background: '#00a095',
        '&:before': {
          background: '#00a095',
        },
        '&:after': {
          background: '#00a095',
        },
      },
    },
  }[preset],
  {
    [ButtonSize.LARGE]: {
      height: '62px',
      padding: '17px 26px',
    },
    [ButtonSize.MEDIUM]: {
      minWidth: '154px',
      height: '56px',
      padding: '18px 21px',
    },
    [ButtonSize.SMALL]: {
      minWidth: '107px',
      height: '44px',
      padding: '10px 22px',
    },
  }[size],
  isLoading ? {
    'span': {
      opacity: 0,
    },
  } : {
    'span': {
      opacity: 1,
    },
  },
])

const ButtonObj = styled.button<ButtonProps>(dynamicStyles)

const Button: React.FC<ButtonProps> = props => {
  const { isLoading = false, children, ...rest } = props
  return (
    <ButtonObj isLoading={isLoading} disabled={isLoading} {...rest}>
      <span>{children}</span>
      {isLoading && <Loading className="loader" />}
    </ButtonObj>
  )
}

export default Button
